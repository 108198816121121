import wkx from 'wkx';
//import Buffer from 'buffer'



//var wkx = require('wkx');
//var Buffer = require('buffer').Buffer;
 



module.exports.run = function run2(wkbBuffer){
   // var wkbBuffer = Buffer.from('0103000020840800000100000005000000E3A59B046A422741894160651E681341B4C8763E7842274177BE9F9A3168134104560E2D734227413BDF4F8D4567134146B6F3FD66422741DF4F8D9746671341E3A59B046A422741894160651E681341', 'hex');
    console.log(wkbBuffer);

    var geometry = wkx.Geometry.parse(wkbBuffer);
    return geometry.toGeoJSON(); 
     // console.log(geometry.toGeoJSON()); 
    //alert(geometry.toGeoJSON());  
   //  console.log("sassdsad");
};